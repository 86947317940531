.black {
    font-weight: 600;
    font-size: 45%;
    /* keeping your spacing/position values as-is */
    width: 1px;
    height: 77px;
    /* use a radial gradient for depth */
    background: radial-gradient(circle at center, #333, #000);
    border: 1px solid rgba(160,160,160,0.7);
    color: #E0E0E0;
    /* a softer, larger shadow */
    box-shadow: 0px 4px 6px rgba(0,0,0,0.5);
    position: absolute;
    margin: 0px;
    margin-left: -18px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 0;
    /* round the corners a bit for a bubbly feel */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transform-origin: top center;
    z-index: 2; /* always on top */
    transition: transform 0.2s;
    overflow: hidden;    /* in case the text overflows */
  }
  
.white {
color: black;
font-size: 70%;
width: 40px;
height: 140px;
/* use a subtle vertical gradient for a modern look */
background: linear-gradient(to bottom, #fff, #f0f0f0);
border: none;
/* a softer shadow */
box-shadow: 2px 4px 8px rgba(0,0,0,0.3);
padding-top: 90px;
/* add rounded corners */
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
transform-origin: top center;
z-index: 1; /* always behind black keys */
transition: transform 0.2s;
}
  
/* Hover scaling remains unchanged */
.white:hover,
.black:hover {
  transform: scale(1.05);
}
  