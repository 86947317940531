/* Style for the main container */
.home-container {
  padding: 20px;
  max-width: 800px; /* Adjust based on your design */
  margin: 0 auto; /* Center the content */
  margin-top: 3em;
  background-color: #f9f9f9; /* Light background for the content */
  border-radius: 10px; /* Optional: rounded corners for the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: slight shadow for depth */
}

/* Style for the headings */
.home-container h1 {
  color: #333; /* Dark color for contrast */
  margin-bottom: 16px; /* Space below the heading */
}

/* Style for the paragraphs */
.home-container p {
  color: #666; /* Slightly lighter color for the text */
  line-height: 1.6; /* Spacing between lines */
  margin-bottom: 16px; /* Space below each paragraph */
  text-align: left;
}

  /* Style for links */
.home-container a {
  color: #3194ff; /* Color for links */
  text-decoration: none; /* Remove underline from links */
}

.home-container a:hover {
  color: #007bff; /* Color for links */
  cursor: pointer;
  text-decoration: none; /* Remove underline from links */
}