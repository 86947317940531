:root {
  /* Define your global variables here */
  --text-primary-colour: #eBeBeB;
  --text-secondary-colour: #b9b9b9;
  --text-accent-colour: rgb(52, 53, 65);

  --background-primary-colour: rgb(52, 53, 65);
  --background-secondary-colour: rgb(0, 0, 0);
  --background-accent-colour: rgb(228, 228, 228);

  --font-family: 'Calibri', sans-serif;
  --base-spacing: 8px;
  /* Add more variables as needed */
}

body {
  min-width: 380px;
  font-family: 'Calibri', sans-serif; /* Add Calibri font here */
  background-color: var(--background-primary-colour);
  color: var(--text-primary-colour);
  margin: 0;
}

#RootApplicationContainer {
  text-align: center;
}

#AppicationIcon{
  width: 10;
  height: 10;
}

#headerSpacer{
  height: 20px;
}
