/* Header container */
.Header-container {
    min-width: 380px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 2em;
    background-color: var(--background-secondary-colour);
    color: var(--text-secondary-colour);
}

/* Navbar */
nav {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* Navbar links */
.headerNavigationButton {
    white-space: nowrap;
    text-wrap: nowrap;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
    background-color: var(--background-secondary-colour);
    color: white;
    text-decoration: none;
    font-size: 1em;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid var(--background-primary-colour);
    transition: background-color 0.5s, color 0.5s;
}

/* Hover effect on links */
.headerNavigationButton:hover {
    border: 1px solid var(--background-secondary-colour);
    background-color: var(--background-primary-colour);
}

.headerNavigationButton:active {
    color: var(--text-secondary-colour);
}

/* Media query for low-width devices */
@media screen and (max-width: 600px) {
    .Header-container {
        flex-direction: column;
        padding: 1em;
    }
    nav {
        margin-top: 1em;
    }
    .headerNavigationButton {
        padding: 8px 12px;
        font-size: 0.9em;
    }
}


