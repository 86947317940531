/* PrivacyPage.css */

/* Style for the main container */
.privacy-container {
  padding: 20px;
  width: 85%;
  max-width: 800px; /* Adjust based on your design */
  margin: 0 auto; /* Center the content */
  margin-top: 3em;
  background-color: #f9f9f9; /* Light background for the content */
  border-radius: 4px; /* Optional: rounded corners for the container */
}

/* Style for the headings */
.privacy-container h1 {
  color: #333; /* Dark color for contrast */
  margin-bottom: 16px; /* Space below the heading */
}

/* Style for the paragraphs */
.privacy-container p {
  color: #666; /* Slightly lighter color for the text */
  line-height: 1.6; /* Spacing between lines */
  margin-bottom: 16px; /* Space below each paragraph */
  text-align: left;
}

/* Style for links */
.privacy-container a {
  color: #007bff; /* Color for links */
  text-decoration: none; /* Remove underline from links */
}

.privacy-container a:hover {
  color: #0059b9; /* Color for links */
  cursor: pointer;
  text-decoration: none; /* Remove underline from links */
}