:root {
  --control-width: 160px;
  --control-bg: #f0f0f0;
  --border-color: #e3e3e3;
  --primary-padding: 0.5rem;
  --border-radius: 0.5rem;
  --font-stack: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  --font-size: 0.9rem;
}

/* Grouped common controls using attribute selector */
[id$="Selector"],
#lastNote,
#currentKey,
#lastChordNote,
#currentChord {
  display: block;
  width: var(--control-width);
  margin: 0.5rem auto;
  padding: var(--primary-padding);
  text-align: left;
  font-family: var(--font-stack);
  font-size: var(--font-size);
  color: #222;
  background: var(--control-bg);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  transition: opacity 0.2s ease;
  opacity: 0;
  pointer-events: none;
}

/* Specific selector adjustments */
#chordSelector,
#keySelector,
#inversionSelector {
  width: calc(var(--control-width) + 20px);
  margin-bottom: 0.75rem;
}

#piano {
  display: grid;
  place-content: center;
  min-height: 7.5rem;
  padding: 1rem 0;
}

#wrapper {
  text-align: center;
  font-size: 1.75rem;
  font-family: var(--font-stack);
  padding: 1rem;
}

/* Modern hover/focus states */
[id$="Selector"]:focus {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}

[id$="Selector"],
.control-item {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.is-visible {
  opacity: 1 !important;
  pointer-events: all !important;
}

/* Responsive considerations */
@media (max-width: 480px) {
  :root {
    --control-width: 90%;
    --primary-padding: 0.75rem;
  }
  
  #wrapper {
    font-size: 1.25rem;
  }
}

#keyPianoWrapper, #chordPianoWrapper{
  min-height: 200px;
  align-self: center;
  justify-self: center;
  font-size: 21px;
}

.chordHighlighted.white {
  color: #000000;
  background: #ff8787;
  padding-top: 75px;
  height: 155px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chordHighlighted.black {
  color: #000000;
  background: #ff7070;
  height: 84px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chordPrimaryHighlighted.white{
  color: #000000;
  background: #ffc38a;
  padding-top: 80px;
  height: 150px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chordPrimaryHighlighted.black {
  color: #000000;
  background: #ffbe6f;
  height: 84px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.keyHighlighted.white {
  background: rgb(146, 255, 219);
  padding-top: 80px;
  height: 150px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.keyHighlighted.black {
  color: #E0E0E0;
  background: #005f3f;
  height: 84px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.keyPressed.white {
  background: #ff7878;
  color: black;
}

.keyPressed.black {
  background: #610000;
}

.keyPressed.white.keyHighlighted {
  background: #82cdff;
}

.keyPressed.black.keyHighlighted {
  background: #004d6b;
}