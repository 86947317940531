
.Contact-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

form{
    margin: 0;
    display: flex;
    flex-direction: column;
}

textarea {
    height: 150px;
    padding: 15px;
    box-sizing: border-box;
    max-width: 840px;
    width: 95vw;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 0 auto; /* Center the content */
    margin-top: 2em;
    background-color: #f9f9f9; /* Light background for the content */
    border-radius: 4px; /* Optional: rounded corners for the container */
  }

.submitSuggestionButton {
  height: 3em;
  width: 7em;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background-color: var(--background-secondary-colour); /* Slightly lighter background on hover */
  border: 1px solid var(--background-secondary-colour);

  color: white; /* Link color */
  text-decoration: none; /* Remove underline */

  transition: background-color 0.5s, color 0.5s; /* Smooth transition for hover effect */
}  

/* Hover effect on links */
.submitSuggestionButton:hover{
    border: 1px solid var(--background-secondary-colour);
    background-color: var(--background-primary-colour); /* Slightly lighter background on hover */
}

.submitSuggestionButton:active {
    color: var(--background-secondary-colour);
}

/* Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    color: #333;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
.popup-content {
position: relative;
background: rgba(255, 255, 255, 0.9);
padding: 2rem;
border-radius: 15px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10px);
max-width: 90%;
width: 400px;
text-align: center;
animation: popup 0.3s ease-out;
}

.popup-content.success {
border: 2px solid #4CAF50;
}

.popup-content.error {
border: 2px solid #f44336;
}

.popup-close {
position: absolute;
top: 10px;
right: 10px;
background: none;
border: none;
font-size: 1.5rem;
cursor: pointer;
color: #666;
transition: color 0.2s;
}

.popup-close:hover {
color: #333;
}

@keyframes popup {
from {
    transform: scale(0.8);
    opacity: 0;
}
to {
    transform: scale(1);
    opacity: 1;
}
}

/* Update button styles for disabled state */
.submitSuggestionButton:disabled {
background: #cccccc;
cursor: not-allowed;
opacity: 0.7;
}
