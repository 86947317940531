.modern-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modern-popup-content {
  background: white;
  color:#333;
  padding: 2rem;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out;
}

.success-message {
  text-align: center;
  color: #4CAF50;
}

.warning-text {
  color: #ff4444;
  margin: 1rem 0;
  font-weight: 500;
}

.confirmation-input {
  width: 100%;
  padding: 12px;
  margin: 1rem 0;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
}

.popup-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.confirm-delete-btn {
  background: #ff4444;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
  flex: 1;
}

.confirm-delete-btn:disabled {
  background: #ff9999;
  cursor: not-allowed;
}

.cancel-delete-btn {
  background: #f0f0f0;
  color: #333;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
  flex: 1;
}

.home-link {
  background: #4285f4;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  flex: 1;
}

.home-link:hover {
  background: rgb(33, 100, 207);
  color: rgb(255, 255, 255);
}

.home-link:active {
  background: rgb(24, 71, 148);
  color: rgb(255, 255, 255);
}

.delete-account-btn {
  min-width: 20em;
  background: #ff4444;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-top: 2rem;
}

.delete-account-btn:hover {
  transform: translateY(-2px);
  background: #bd3232;
}

.delete-account-btn:active {
  background: #8c2525;
}

.cancel-delete-btn:hover {
  background-color: #d1d1d1;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}