.signInWithGoogleButton, .signInWithFacebookButton, .signInButton, .signOutButton {
  text-wrap: nowrap;
  white-space: nowrap;
  border: 1px solid var(--background-secondary-colour);
  background-color: black; /* Slightly lighter background on hover */
  color: var(--text-primary-colour); /* Light text */
  border: 1px solid var(--background-primary-colour);
  margin-top: 15px;
  margin-left: 15px;
  padding: 20px 40px;
  cursor: pointer;
  text-decoration: none; /* Remove underline */
  font-size: 1em; /* Font size */
  padding: 10px 15px; /* Padding around links for larger click area */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}
  
  .signInWithGoogleButton:hover, .signInWithFacebookButton:hover, .signInButton:hover, .signOutButton:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    border: 1px solid var(--background-secondary-colour);
    background-color: var(--background-primary-colour); /* Slightly lighter background on hover */
}
  
  .signInWithGoogleButton:active, .signInWithFacebookButton:active, .signInButton:active, .signOutButton:active {
    color: var(--text-secondary-colour); /* Light text */
    background-color: var(--background-secondary-colour); /* Even lighter on active */
    border: 1px solid var(--background-secondary-colour);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Smaller shadow for pressed effect */
}
  

  /* Navbar links */
.Header-container nav a {
  color: var(--text-primary-colour);
  text-decoration: none; /* Remove underline */
  font-size: 1em; /* Font size */
  padding: 10px 15px; /* Padding around links for larger click area */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid var(--background-primary-colour);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}
  
  /* Hover effect on links */
  .Header-container nav a:hover {
  border: 1px solid var(--background-secondary-colour);
  background-color: var(--background-primary-colour); /* Slightly lighter background on hover */
}
  
/* Add this CSS to your stylesheet */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(2px);
}

.popup-container {
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  position: relative;
  animation: slideIn 0.3s ease-out;
}

.popup-content {
  padding: 2rem;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 1.8rem; /* Increased from 1.5rem */
  cursor: pointer;
  color: #666;
  padding: 8px; /* Increased from 4px */
  line-height: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Circular shape */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  background-color: rgba(0, 0, 0, 0.05); /* Subtle hover background */
  z-index: 1;
}

.close-button:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.1); /* More visible click feedback */
}

.close-button:active {
}

h2 {
  margin: 0 0 0.5rem 0;
  font-size: 1.8rem;
  color: #333;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
}

.social-button {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: transform 0.1s ease, background-color 0.2s ease;
}

.social-button:hover {
  transform: translateY(-1px);
}

.social-button:active {
  transform: translateY(0);
}

.google {
  background: #4285f4;
  color: white;
}

.facebook {
  background: #3b5998;
  color: white;
}

.social-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.divider {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.divider-line {
  flex: 1;
  height: 1px;
  background: #ddd;
}

.divider-text {
  padding: 0 1rem;
  color: #666;
  font-size: 0.9rem;
}

.email-button {
  width: 100%;
  padding: 12px;
  background: #f0f0f0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  color: #333;
}

.email-button:hover {
  background: #e0e0e0;
}

.terms {
  font-size: 0.75rem;
  color: #666;
  text-align: center;
  margin-top: 1.5rem;
}

.terms a {
  color: #4285f4;
  text-decoration: none;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}